<template>
  <div :id="id" :class="['m-new-footer', isPC ? 'z-web' : 'z-phone', theme.primaryColor ? theme.primaryColor : '']">
    <div class="u-footer-wrap">
      <div class="u-logo">
        <VisualItem v-if="isPC" :visualKeys="[logo.visualKeys, logo_2x.visualKeys]" @visual="handleVisual">
          <CMSImage
            :img="logo.value"
            :img2x="logo_2x.value"
            class="u-img"
          />
        </VisualItem>
        <VisualItem v-else :visualKeys="[logo_2x_h5.visualKeys, logo_3x_h5.visualKeys]" @visual="handleVisual">
          <CMSImage
            :img="logo_2x_h5.value ? logo_2x_h5.value : logo.value"
            :img2x="logo_3x_h5.value ? logo_3x_h5.value : logo_2x.value"
            class="u-img"
          />
        </VisualItem>
      </div>
      <!-- 分割线 -->
      <div v-if="!isPC" class="line" />
      <div class="u-footer-list">
        <VisualItem :visualKeys="list.visualKeys" @visual="handleVisual" theme="light">
          <div
            v-for="(item, index) in list.value"
            :key="`service-${index}`"
            :class="['m-service', !isPC ? 'clearfix' : '']"
          >
            <VisualItem :visualKeys="item.title.visualKeys" @visual="handleVisual">
              <p class="detail-tlt">
                {{ item.title.value }}
              </p>
              <VisualItem :visualKeys="item.detail.visualKeys" @visual="handleVisual" class="detail">
                <div
                  v-for="(val, key) in item.detail.value"
                  :key="`detail-${key}`"
                  class="detail-list"
                >
                  <VisualItem :visualKeys="[val.text.visualKeys, val.link.visualKeys]" @visual="handleVisual">
                    <CMSLink
                      v-if="val.link && val.link.value"
                      :link="val.link.value"
                      class-name="u-link"
                    >
                      {{ val.text.value }}
                    </CMSLink>
                    <span v-else>{{ val.text.value }}</span>
                  </VisualItem>
                </div>
              </VisualItem>
            </VisualItem>
          </div>
        </VisualItem>
      </div>
      <div v-if="!isPC" class="line" />
      <div class="links-code g-l">
        <VisualItem v-if="isPC" :visualKeys="[img.visualKeys, img_2x.visualKeys]" @visual="handleVisual">
          <CMSImage
            :img="img.value"
            :img2x="img_2x.value"
          />
        </VisualItem>
        <VisualItem v-else :visualKeys="[img_2x_h5.visualKeys, img_3x_h5.visualKeys]" @visual="handleVisual">
          <CMSImage
            :img="img_2x_h5.value ? img_2x_h5.value : img.value"
            :img2x="img_3x_h5.value ? img_3x_h5.value : img_2x.value"
          />
        </VisualItem>
        <p>关注公众号</p>
      </div>
      <div v-if="!isPC" class="line" />
      <ul class="u-footer-info">
        <VisualItem :visualKeys="infoList.visualKeys" @visual="handleVisual">
          <div
            v-for="(item, index) in infoList.value"
            :key="`service-${index}`"
            class="m-info-list"
          >
            <VisualItem :visualKeys="[item.text.visualKeys, item.link.visualKeys]" @visual="handleVisual">
              <CMSLink
                :link="item.link.value"
                class-name="link"
                style="color: #fff"
              >
                {{ item.text.value }}
              </CMSLink>
            </VisualItem>
          </div>
        </VisualItem>
      </ul>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import config from './config.json'
import Load from '~/assets/js/load.js'
import { fixConfig } from '~/assets/js/visualFix'
import CMSImage from '~/components/common/Image'
import CMSLink from '~/components/common/Link'
import VisualItem from '~/components/common/VisualItem'

const componentKey = 'NewFooter'

export default {
  components: {
    CMSImage,
    CMSLink,
    VisualItem
  },
  props: {
    id: {
      type: [String, Number],
      default: 'NewFooter'
    },
    mode: {
      type: String,
      default: ''
    },
    reqData: {
      type: Object,
      default() {
        return {}
      }
    },
    isPC: {
      type: Boolean,
      default: true
    }
  },
  data() {
    const data = fixConfig(_.cloneDeep(config[componentKey]), this.mode === 'dev' ? {
      id: componentKey,
      reqData: Load(_.cloneDeep(config), componentKey)
    } : this.$store.getters.getComponent(this.id))

    this.$store.commit('updateComponentById', {
      id: this.id || componentKey,
      data
    })

    return {
      ...data
    }
  },
  computed: {
    theme() {
      return this.$store.state.theme
    }
  },
  methods: {
    handleVisual(obj) {
      this.$emit('visual', obj)
    }
  }
}
</script>

<style lang="postcss">
@import '~/assets/css/4mixin.css';

.m-new-footer {
  &.z-phone {
    background: #0f0e0c;
    padding: pxtorem(80) pxtorem(40) pxtorem(32);

    .u-footer-wrap {
      .u-logo {
        width: 70%;
        margin: 0 auto pxtorem(80);
        text-align: center;

        .u-img {
          width: 90%;
        }
      }

      .line {
        height: pxtorem(1);
        width: 100%;
        background: #444;
      }

      .m-service {
        margin-bottom: pxtorem(60);

        &:last-child {
          margin-bottom: pxtorem(80);
        }

        &:first-child {
          margin-top: pxtorem(80);
        }

        .detail-tlt {
          font-size: pxtorem(28);
          color: #fff;
          opacity: 0.4;
          line-height: pxtorem(40);
          margin-bottom: pxtorem(30);
        }

        .detail {
          span {
            color: #f5f5f5;
            opacity: 0.75;
          }

          .detail-list {
            display: inline-block;
          }

          .detail-list:first-child .u-link {
            margin-left: 0;
          }

          .u-link {
            line-height: pxtorem(40);
            color: #f5f5f5;
            opacity: 0.75;
            margin-left: pxtorem(120);
          }
        }
      }

      .links-code {
        margin: pxtorem(56) 0;
        float: none;
        text-align: center;
        color: #fff;
        font-size: pxtorem(28);

        img {
          margin-bottom: pxtorem(24);
          width: 100px;
          height: 100px;
        }

        p {
          line-height: pxtorem(40);
        }
      }

      .u-footer-info {
        margin-top: pxtorem(32);
        text-align: center;
        color: #fff;
        opacity: 0.4;
        font-size: pxtorem(20);
      }
    }
  }

  &.z-web {
    padding: 50px 0 22px;
    background-color: #0f0e0c;
    color: #fff;

    .u-footer-wrap {
      width: 1200px;
      margin: 0 auto;
    }

    .u-logo {
      display: inline-block;
      width: 200px;

      .u-img {
        max-width: 200px;
      }
    }

    .u-footer-list {
      display: inline-block;
      height: 250px;
      vertical-align: top;
      margin-left: 214px;

      .m-service {
        width: 172px;
        display: inline-block;
        vertical-align: top;

        .detail {
          padding: 14px 0 0;
        }

        .detail-tlt {
          font-size: 18px;
          margin-bottom: 16px;
        }

        .detail-list {
          margin-bottom: 24px;
          color: #f5f5f5;

          span {
            opacity: 0.5;
            line-height: 30px;
          }
        }
      }

      .u-link {
        color: #fff;
        opacity: 0.5;
        line-height: 30px;
      }
    }

    .links-code {
      text-align: center;
      float: right;

      img {
        width: 122px;
        height: 122px;
      }

      p {
        line-height: 14px;
        padding-top: 10px;
        opacity: 0.6;
      }
    }

    .u-footer-info {
      width: 100%;
      line-height: 20px;
      text-align: center;
      color: #6f6f6f;
      font-size: 0;

      .m-info-list {
        display: inline-block;
        vertical-align: middle;
        margin-right: 20px;
        font-size: 14px;

        .link {
          color: #f5f5f5;
          opacity: 0.6;
        }
      }
    }
  }
}
</style>
